import React from 'react';
import { Col } from 'reactstrap';

const PageSizeSelector = ({ pageSize, onPageSizeChange }) => {
    return (
        <div className="">
            <Col md={12}>
                <label htmlFor="" style={{ fontSize: "0.875rem" }}>Göster</label>
                <select className="form-control form-control-sm" value={pageSize} onChange={(e) => onPageSizeChange(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </Col>
        </div>
    );
};

export default PageSizeSelector;
