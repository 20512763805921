/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Container, Alert, CardHeader, Button, Label, Input, Table } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import api_helper, { get, post } from '../../helpers/api_helper'
import { useParams } from "react-router-dom";
import BrokerRejectedRefers from "../../components/Common/BrokerRejectedRefers";





const NewUser = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Anasayfa", link: "/" },
    { title: "Kullanıcılar", link: "/users" },
    { title: `Yeni Kullanıcı Oluştur`, link: "#" },
  ]);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [userSettings, setUserSettings] = useState({
    fullname: '',
    email: '',
    password: '',
    repassword: '',
  });



  const handleChange = (e) => {
    setUserSettings({ ...userSettings, [e.target.name]: e.target.value });
  };

  const createUser = async () => {
    try {
      setIsLoading(true);
      if (userSettings.password !== userSettings.repassword) throw new Error("Şifreler eşit değil.")

      const response = await post(`user/create`, {
        fullname: userSettings.fullname,
        email: userSettings.email,
        password: userSettings.password,
      });

      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }

      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }






  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
          <Row>



            <Row className="d-flex justify-content-center">
              <Col xs={12} md={12} >
                <div>


                  <Row className="mb-3 d-flex justify-content-center ">
                    {error && (
                      <div>
                        <Col xs={12} md={12} >
                          <Alert color="danger">{error.message}</Alert>
                        </Col>
                      </div>
                    )}
                    <Col xs="12" md="8">
                      <Card>
                        <CardHeader>
                          Yeni Kullanıcı Oluştur
                        </CardHeader>
                        <CardBody>
                          <Row className="mb-3">
                            <Col md={6} xs="12" className="mb-3">
                              <Label className="">Kullanıcı Adı</Label>
                              <Input type="text" value={userSettings.fullname} name="fullname" onChange={handleChange} placeholder="Kullanıcının Adı" />
                            </Col>
                            <Col md={6} xs="12" className="mb-3">
                              <Label className="">E-Posta</Label>
                              <Input type="text" value={userSettings.email} name="email" onChange={handleChange} placeholder="" />
                            </Col>
                            <Col md={6} xs="12" className="mb-3">
                              <Label className="">Şifre</Label>
                              <Input type="text" name="password" value={userSettings.password} onChange={handleChange} placeholder="Şifre" />
                            </Col>
                            <Col md={6} xs="12" className="mb-3">
                              <Label className="">Şifre Tekrar</Label>
                              <Input type="text" name="repassword" value={userSettings.repassword} onChange={handleChange} placeholder="Şifre tekrar" />
                            </Col>
                            <Col md={12} xs="12" className="d-flex justify-content-end">
                              <Button color="success" onClick={createUser}>Oluştur</Button>
                            </Col>
                          </Row>

                        </CardBody>
                      </Card>
                    </Col>

                  </Row>
                </div>


              </Col>

            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

}


export default NewUser;
