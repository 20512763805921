

import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Table, Row, Col } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";

const UserDetailCard = ({ data }) => {
    const navigate = useNavigate();

    const [percent, setPercent] = useState(data.percent);

    function formatISODateToReadable(isoDateString) {
        const date = new Date(isoDateString);

        return date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // 24 saat formatı kullan
        });
    }


    return (
        <React.Fragment>
            <Card className='shadow-none'>
                <CardBody>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/user-detail/${data.id}`}>
                        <div className=''>
                            <div className="title ">
                                <div style={{ fontWeight: 'bolder', fontSize: "1.2rem" }}>{data.fullname}</div>
                            </div>
                            <div className="email">
                                <div style={{ fontWeight: '300', fontSize: "1.2rem", marginBottom: 10 }}>{data.email}</div>

                            </div>
                            <div style={{ fontWeight: '300', fontSize: "1rem" }}>
                                {formatISODateToReadable(data.createdAt)}
                            </div>
                        </div>
                    </Link>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};
export default UserDetailCard;

