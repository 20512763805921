/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Container, Alert, CardHeader, Button } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import api_helper, { get } from '../../helpers/api_helper'
import { useParams } from "react-router-dom";
import LeadTable from "../../components/Common/LeadTable";
import Swal from 'sweetalert2';




const LeadDetailPage = () => {
  const { id } = useParams();
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Anasayfa", link: "/" },
    { title: "Müşteriler", link: "/leads" },
    { title: `Müşteri Detayları #${id}`, link: "#" },
  ]);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [visibleDeveloperPanel, setVisibleDeveloperPanel] = useState(false);
  const [transferLogPanel, setTransferLogPanel] = useState(false);


  useEffect(() => {
    console.log(id);
    getData();
  }, [])

  const getData = async () => {
    try {
      setIsLoading(true);

      const response = await get(`lead/detail/${id}`);
      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.data;
      setData(data);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.data.message) {
        setError(error.response.data);
        setIsLoading(false);

      } else {
        setError(error);
        setIsLoading(false);

      }
    }
  }
  const handleReTransfer = () => {
    Swal.fire({
      title: 'Emin misiniz?',
      text: "Bu işlemi gerçekleştirmek istediğinize emin misiniz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet, onaylıyorum!',
      cancelButtonText: 'Vazgeç'
    }).then((result) => {
      if (result.isConfirmed) {
        sendReTransferAPI();
      }
    });
  };

  const sendReTransferAPI = async () => {
    try {
      await get(`lead/re-transfer/${id}`);
      Swal.fire(
        'Başarılı!',
        'İşleminiz başarıyla tamamlandı.',
        'success'
      );
      await getData();
    } catch (error) {
      if (error.response && error.response.data.message) {
        Swal.fire(
          'Hata!',
          error.response.data.message,
          'error'
        );
      } else {
        Swal.fire(
          'Hata!',
          'İşlem sırasında bir hata meydana geldi.',
          'error'
        );
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Lead Detayı" breadcrumbItems={breadcrumbItems} />
          <Row>
            {isLoading && (
              <div>
                <Alert color="light">Yükleniyor...</Alert>
              </div>
            )}
            {error && (
              <div>
                <Col xs={12} md={12} >
                  <Alert color="danger">{error.message}</Alert>
                </Col>
              </div>
            )}


            {data && (
              <Row>
                <Col xs={12} md={12} >
                  <div>
                    <hr />
                    <div className="d-flex gap-2">
                      <Button color="info" onClick={handleReTransfer}>Yeniden Transfer
                      </Button>

                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h2>
                          <div className="d-flex">
                            <div>
                              {data?.result?.name} <strong>#{data?.result?.id}</strong>
                            </div>
                            <div>
                              < i class="ri-arrow-right-s-line"></i>
                            </div>
                            <div>
                              <strong>{data.result.broker && data.result.broker_detail.name}</strong>
                            </div>
                          </div>
                        </h2>
                        <p>{data.result.name} adlı müşteri <strong>{data.result.broker && data.result.broker_detail.name}</strong> şirketine atandı.</p>
                      </div>
                      <div>
                        {data.result.transfered ? (
                          <div className="badge bg-success-subtle text-dark font-size-20">Transfer Edildi ✅</div>
                        ) : (
                          <div className="badge bg-danger-subtle text-dark font-size-20">Beklemede ⚠️</div>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="mb-2">
                      <strong>E-Posta:</strong> {data.result.email}
                    </div>
                    <div className="mb-2">
                      <strong>Telefon:</strong> {data.result.phone}
                    </div>
                    <div className="mb-2">
                      <strong>Refer:</strong> {data.result.refer}
                    </div>
                  </div>
                  <hr />
                  <Card>
                    <CardHeader>Daha önceki başvurular</CardHeader>
                    <CardBody>
                      {data.records.length > 0 ? (
                        <LeadTable data={data} size={"sm"} ></LeadTable>

                      ) : (
                        <Alert color="info">Daha önce kampanyaya başvurmamış</Alert>
                      )}
                    </CardBody>
                  </Card>

                </Col>
                <Col xs={12}>
                  <div className="mb-3">
                    <h3>Buradaki veriler developer için</h3>
                  </div>
                  <Card>
                    <CardHeader >
                      <strong>JSON Response</strong>
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        size="sm"
                        onClick={() => setVisibleDeveloperPanel(!visibleDeveloperPanel)}>
                        {visibleDeveloperPanel ? "Gizle" : "Göster"}
                      </Button>
                    </CardHeader>
                    <CardBody style={{
                      display: visibleDeveloperPanel ? "block" : "none",
                    }}>
                      <code>{JSON.stringify(data)}</code>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader >
                      <strong>Transfer Logs</strong>
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        size="sm"
                        onClick={() => setTransferLogPanel(!transferLogPanel)}>
                        {transferLogPanel ? "Gizle" : "Göster"}
                      </Button>
                    </CardHeader>
                    <CardBody style={{
                      display: transferLogPanel ? "block" : "none",
                    }}>
                      <code>{JSON.stringify(data)}</code>
                    </CardBody>
                  </Card>

                </Col>

              </Row>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

}


export default LeadDetailPage;
