

import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Table, Row, Col } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";

const BrokerCard = ({ data }) => {
    const navigate = useNavigate();

    const [percent, setPercent] = useState(data.percent);

    function formatISODateToReadable(isoDateString) {
        const date = new Date(isoDateString);

        return date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // 24 saat formatı kullan
        });
    }

    return (
        <React.Fragment>
            <Card className='shadow-none'>
                <CardBody>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/broker-detail/${data.id}`}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className="title d-flex align-items-center gap-2">
                                <div style={{ fontWeight: 'bolder', fontSize: "1.2rem" }}>{data.name}</div>
                                <div style={{ padding: "5px 10px", background: '#f2f2f2', borderRadius: 3 }} className='text-muted'>{data.alias}</div>
                            </div>
                            <div style={{ fontWeight: '300', fontSize: "1.2rem" }}>
                                %{data.percent}
                            </div>
                        </div>
                    </Link>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};
export default BrokerCard;

