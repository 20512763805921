import React from 'react'
import { Col, Card, CardBody, Alert } from "reactstrap";

export default function BusyHours({ data }) {
    return (
        <>
            <Col xs="12">
                <Alert color='info'>
                    <h5 style={{ marginBottom: 5 }}>7 günlük başvuru saati analizi</h5>

                    Gün içerisinde ortalama:
                    {data?.map((item) => (
                        <>
                            {' '}
                            <strong>{item.busiest_hour}:00 ({item.count}) / </strong>
                            {' '}
                        </>
                    ))}
                    saatlerinde başvurular yoğunluk gösteriyor.
                </Alert>
            </Col>
        </>
    )
}
