import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import { tr } from "date-fns/locale"; // Türkçe yerelleştirme
import MyDateRangePicker from "./DateRangePicker";
import DistrubitionbyBrokers from "./DistrubitionbyBrokers";
import { get } from "../../helpers/api_helper";
import LeadTable from "../../components/Common/LeadTable";
import SourcesbyBroker from "./SourcesbyBroker";
import BusyHours from "./BusyHours";



const Dashboard = () => {
  const [breadcrumbItems, setBeadcrumbItems] = useState([
    { title: "Lead Manager", link: "/" },
    { title: "İzleme Paneli", link: "#" },
  ]);

  const [reports, setReports] = useState([
    { icon: "ri-survey-line", title: "Toplam Müşteri", value: "0" },
    { icon: "ri-check-line", title: "Transfer Edilen", value: "0" },
    { icon: "ri-briefcase-4-line", title: "Beklemede", value: "0" },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState();



  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await get(`report/generalReport`);
      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.data;
      setData(data);
      setReports([
        { icon: "ri-survey-line", title: "Toplam Müşteri", value: response?.data?.total?.totalLead.total_lead },
        { icon: "ri-check-line", title: "Transfer Edilen", value: response?.data?.total?.nonTransferedLeads.total_lead },
        { icon: "ri-briefcase-4-line", title: "Beklemede", value: response?.data?.total?.queuedLeads.total_lead },
      ])
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }


  useEffect(() => {
    getData();
  }, [])


  if (isLoading) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="İzleme Paneli" breadcrumbItems={breadcrumbItems} />
          </Container>
        </div>
      </React.Fragment>
    );
  }

  if (error) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="İzleme Paneli" breadcrumbItems={breadcrumbItems} />
            <Row>
              <Col xl={12}>
                Bir hata oluştu.
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <MyDateRangePicker></MyDateRangePicker> */}
          <Breadcrumbs title="İzleme Paneli" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xl={8}>
              <Row>
                <BusyHours data={data?.busyHours}></BusyHours>
                <MiniWidgets reports={reports} />
                {/* recent activity */}
                {/* <DistrubitionbyBrokers reportData={data?.brokerLeadsDistribution} /> */}
                <Col>
                  {/* sources */}
                  <Row>
                    <Sources data={data?.leadCountsByRefer} />

                  </Row>

                  <Card>
                    <CardHeader> Son Başvurular</CardHeader>
                    <CardBody>
                      <LeadTable data={data?.recentActivity} id={false} email={false} size="sm"></LeadTable>
                    </CardBody>
                  </Card>

                </Col>
              </Row>
              {/* revenue Analytics */}
              {/* <RevenueAnalytics /> */}
            </Col>

            <Col xl={4}>
              {/* sales Analytics */}
              <DistrubitionbyBrokers reportData={data?.brokerLeadsDistribution} />
              <RecentlyActivity data={data?.recentActivity?.records} />


              {/* earning reports */}
              {/* <EarningReports /> */}
            </Col>
          </Row>
          <Row>
            <SourcesbyBroker data={data?.brokerCountsByRefer} />

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
