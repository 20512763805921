/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Container, Label } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PaginationNumbers from "../../components/Common/PaginationNumbers";
import api_helper, { get } from '../../helpers/api_helper'
import LeadsFilter from "../../components/Common/LeadsFilter";
import BrokerCard from "../../components/Common/BrokerCard";
import PageSizeSelector from "../../components/Common/PageSizeSelector";



const Brokers = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Anasayfa", link: "/" },
    { title: "Firmalar", link: "/brokers" },
  ]);

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(15);

  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getData();
  }, [currentPage, dataLimit])

  const handlePageSizeChange = (newSize) => {
    setDataLimit(newSize);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const queryParameters = `page=${currentPage}&limit=${dataLimit}`;
      const response = await get(`broker/list-all?${queryParameters}`);
      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.data;
      setData(data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Firmalar" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                {isLoading && (
                  <div>Yükleniyor.</div>
                )}
                {error && (
                  <div>{error.message}.</div>
                )}
                {data && (
                  <CardBody>
                    <div className=" d-flex justify-content-between align-items-center">
                      <div className="mb-3">
                        <PageSizeSelector pageSize={dataLimit} onPageSizeChange={handlePageSizeChange}></PageSizeSelector>
                      </div>
                    </div>

                    <Row>
                      {data.records.map((data) => (
                        <Col md="4" xs="12">
                          <BrokerCard data={data}></BrokerCard>
                        </Col>
                      ))}
                    </Row>
                    <PaginationNumbers setCurrentPage={(e) => setCurrentPage(e)} currentPage={data.currentPage} totalPages={data.totalPages} />
                  </CardBody>
                )}

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

}


export default Brokers;
