import React, { Component, useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";


const DistrubitionbyBrokers = ({ reportData }) => {
  const colors = ["#5664d2", "#1cbb8c", "#eeb902", "#ff6347"]; // Renkleri ihtiyacınıza göre değiştirebilirsiniz
  const [data, setData] = useState({
    series: [],
    options: {
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: colors,
    },
  });

  useEffect(() => {
    if (reportData) {
      const updatedSeries = reportData.map(item => item.total_leads);
      const updatedLabels = reportData.map(item => item.broker_name);

      setData(prevData => ({
        ...prevData,
        series: updatedSeries,
        options: {
          ...prevData.options,
          labels: updatedLabels,
        },
      }));
    }
  }, [reportData]);

  // Toplam sayıyı hesapla
  const totalLeads = data.series.reduce((a, b) => a + b, 0);

  return (
    <React.Fragment>
      <Col md="12">
        <Card>
          <CardBody>

            <h4 className="card-title mb-4">Firmalara Göre Dağılım</h4>
            <div id="donut-chart" className="apex-charts">
              <ReactApexChart options={data.options} series={data.series} type="donut" height="250" />
            </div>
            <Row>
              {data.series.map((value, index) => (
                <Col xs={4} key={index}>
                  <div className="text-center mt-4">
                    <p className="mb-2 text-truncate">
                      <i className={`mdi mdi-circle font-size-10 me-1`} style={{ color: colors[index] }}></i>
                      {data.options.labels[index]}
                    </p>
                    <h5>{((value / totalLeads) * 100).toFixed(2)} % </h5>
                  </div>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DistrubitionbyBrokers;
