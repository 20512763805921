import axios from "axios";
import { logoutUser } from "../store/actions";

const obj = JSON.parse(localStorage.getItem("authUser"));
//pass new generated access token here
const token = `Bearer ${obj?.data?.token}`;

//apply base url for axios
const API_URL = process.env.REACT_APP_BACKEND_APP_URL || "http://localhost:4000";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // Eğer bir hata varsa ve bu 401 durum kodu ise, özel bir işlem yap
    if (error.response && error.response.status === 401) {
      window.location.href = "/logout";
    }

    // Diğer tüm hatalar için, hatayı olduğu gibi döndür
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}
