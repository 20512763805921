import React, { Component, useState } from "react";
import { Card, CardBody, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";

const Sources = ({ data }) => {
  const [viewMore, setViewMore] = useState(false);
  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-3">Lead Kaynakları</h4>

            <div>
              <div
                className="table-responsive mt-4"
                style={{
                  height: viewMore ? "100%" : "200px",
                }}
              >
                <Table className=" mb-0 table-centered table-nowrap" size="sm">
                  <tbody>
                    {data?.map((item) => (
                      <tr style={{ verticalAlign: "middle" }}>
                        <td style={{ width: "60px" }}>
                          <div className="avatar-xs">
                            <div className="avatar-title rounded-circle">{item.refer[0]}</div>
                          </div>
                        </td>
                        <td>
                          <h5 className="font-size-14 mb-0">{item.refer}</h5>
                        </td>

                        <td>
                          <p className="text-muted mb-0">{item.total_lead}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-center text-center mt-3">
                <Link onClick={() => setViewMore(!viewMore)}>Daha {viewMore ? "az" : "fazla"} göster</Link>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Sources;
