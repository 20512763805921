import React from 'react';
import { ButtonToolbar, ButtonGroup, Button } from 'reactstrap';

const PaginationNumbers = ({ currentPage, totalPages, setCurrentPage }) => {
    const maxPageNumberWindow = 3; // Önceki ve sonraki sayfaların maksimum sayısı

    // Sayfa numaralarını hesapla
    const startPage = Math.max(1, currentPage - maxPageNumberWindow);
    const endPage = Math.min(totalPages, currentPage + maxPageNumberWindow);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // Önceki ve sonraki sayfa fonksiyonları
    const goToPreviousPage = () => {
        setCurrentPage(Math.max(1, currentPage - 1));
    };

    const goToNextPage = () => {
        setCurrentPage(Math.min(totalPages, currentPage + 1));
    };
    const goToFirstPage = () => setCurrentPage(1);
    const goToLastPage = () => setCurrentPage(totalPages);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-end align-items-center">
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button color="light" size='sm' onClick={goToFirstPage} disabled={currentPage === 1}>
                            İlk
                        </Button>
                        <Button color="light" size='sm' onClick={goToPreviousPage} disabled={currentPage === 1}>
                            Önceki
                        </Button>
                        {pageNumbers.map(number => (
                            <Button
                                key={number}
                                size='sm'
                                color={number === currentPage ? "primary" : "light"}
                                onClick={() => setCurrentPage(number)}
                            >
                                {number}
                            </Button>
                        ))}
                        <Button color="light" size='sm' onClick={goToNextPage} disabled={currentPage === totalPages}>
                            Sonraki
                        </Button>
                        <Button color="light" size='sm' onClick={goToLastPage} disabled={currentPage === totalPages}>
                            Son
                        </Button>
                    </ButtonGroup>

                </ButtonToolbar>

            </div>
        </React.Fragment>
    )
};

export default PaginationNumbers;
