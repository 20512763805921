/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Container, Alert, CardHeader, Button, Label, Input, Table } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import api_helper, { get, post } from '../../helpers/api_helper'
import { useParams } from "react-router-dom";
import BrokerRejectedRefers from "../../components/Common/BrokerRejectedRefers";





const UserDetail = () => {
  const { id } = useParams();
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Anasayfa", link: "/" },
    { title: "Kullanıcılar", link: "/users" },
    { title: `Kullanıcı Detayları`, link: "#" },
  ]);
  const [data, setData] = useState();
  const [brokerCustomers, setBrokerCustomers] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [visibleDeveloperPanel, setVisibleDeveloperPanel] = useState(false);
  const [transferLogPanel, setTransferLogPanel] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(15);
  const [filterData, setFilterData] = useState({});

  const [userSettings, setUserSettings] = useState({
    fullname: '',
    email: '',
  });

  const [password, setPassword] = useState({
    password: '',
    repassword: '',
  });

  useEffect(() => {
    console.log(id);
    getData();
  }, []);

  useEffect(() => {
  }, [currentPage, dataLimit, filterData])



  const handleChange = (e) => {
    setUserSettings({ ...filterData, [e.target.name]: e.target.value });
  };

  const handleChangePassword = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const updateUser = async () => {
    try {
      setIsLoading(true);

      const response = await post(`user/update/${id}`, {
        fullname: userSettings.fullname,
        email: userSettings.email
      });

      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }

      setIsLoading(false);
      setSuccess(response.data.message);
      setData(response.data);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }


  const updatePassword = async () => {
    try {
      setIsLoading(true);



      if (password.password !== password.repassword) throw new Error("Şifreler eşit değil.")

      const response = await post(`user/update-password/${id}`, {
        password: password.password
      });

      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }

      setIsLoading(false);
      setError(false);
      setSuccess(response.data.message);

    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }


  const getData = async () => {
    try {
      setIsLoading(true);

      const response = await get(`user/detail/${id}`);
      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.data;
      setData(data);
      setUserSettings({
        fullname: data.fullname,
        email: data.email
      })
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.data.message) {
        setError(error.response.data);
        setIsLoading(false);

      } else {
        setError(error);
        setIsLoading(false);
      }
    }
  }






  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kullanıcı Bilgisi" breadcrumbItems={breadcrumbItems} />
          <Row>
            {isLoading && (
              <div>
                <Alert color="light">Yükleniyor...</Alert>
              </div>
            )}
            {error && (
              <div>
                <Col xs={12} md={12} >
                  <Alert color="danger">{error.message}</Alert>
                </Col>
              </div>
            )}
            {success && (
              <div>
                <Col xs={12} md={12} >
                  <Alert color="success">{success}</Alert>
                </Col>
              </div>
            )}


            {data && (
              <Row>
                <Col xs={12} md={12} >
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h2>
                        <div>
                          # <strong>{data.fullname}</strong>
                        </div>
                      </h2>
                    </div>

                    <Row className="mb-3">
                      <Col xs="12" md="6">
                        <Card>
                          <CardHeader>
                            Kullanıcı Ayarları
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3">
                              <Col md={6} xs="12" className="mb-3">
                                <Label className="">Kullanıcı Adı</Label>
                                <Input type="text" value={userSettings.fullname} name="fullname" onChange={handleChange} placeholder="Kullanıcının Adı" />
                              </Col>
                              <Col md={6} xs="12" className="mb-3">
                                <Label className="">E-Posta</Label>
                                <Input type="text" value={userSettings.email} name="email" onChange={handleChange} placeholder="" />
                              </Col>
                              <Col md={12} xs="12" className="d-flex justify-content-end">
                                <Button color="success" onClick={updateUser}>Güncelle</Button>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs="12" md="6">
                        <Card>
                          <CardHeader>
                            Şifre İşlemleri
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3">
                              <Col md={6} xs="12" className="mb-3">
                                <Label className="">Şifre</Label>
                                <Input type="text" name="password" value={password.password} onChange={handleChangePassword} placeholder="Şifre" />
                              </Col>
                              <Col md={6} xs="12" className="mb-3">
                                <Label className="">Şifre Tekrar</Label>
                                <Input type="text" name="repassword" value={password.repassword} onChange={handleChangePassword} placeholder="Şifre tekrar" />
                              </Col>
                              <Col md={12} xs="12" className="d-flex justify-content-end">
                                <Button color="info" onClick={updatePassword}>Güncelle</Button>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>


                </Col>
                <Col xs={12}>
                  <div className="mb-3">
                    <h3>Buradaki veriler developer için</h3>
                  </div>
                  <Card>
                    <CardHeader >
                      <strong>JSON Response</strong>
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        size="sm"
                        onClick={() => setVisibleDeveloperPanel(!visibleDeveloperPanel)}>
                        {visibleDeveloperPanel ? "Gizle" : "Göster"}
                      </Button>
                    </CardHeader>
                    <CardBody style={{
                      display: visibleDeveloperPanel ? "block" : "none",
                    }}>
                      <code>{JSON.stringify(data)}</code>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader >
                      <strong>Transfer Logs</strong>
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        size="sm"
                        onClick={() => setTransferLogPanel(!transferLogPanel)}>
                        {transferLogPanel ? "Gizle" : "Göster"}
                      </Button>
                    </CardHeader>
                    <CardBody style={{
                      display: transferLogPanel ? "block" : "none",
                    }}>
                      <code>{JSON.stringify(data)}</code>
                    </CardBody>
                  </Card>

                </Col>

              </Row>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

}


export default UserDetail;
