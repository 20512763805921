import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, CardHeader } from "reactstrap";
import ReactApexChart from 'react-apexcharts';

const SourcesbyBroker = ({ data }) => {
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    groupDataByBroker(data);
  }, [data]);

  const groupDataByBroker = (data) => {
    const grouped = {};

    data?.forEach(item => {
      if (!grouped[item.broker_name]) {
        grouped[item.broker_name] = [];
      }
      grouped[item.broker_name].push({
        refer: item.refer,
        lead_count: item.lead_count
      });
    });

    for (const broker in grouped) {
      grouped[broker].sort((a, b) => b.lead_count - a.lead_count);
    }

    setGroupedData(grouped);
  };

  return (
    <React.Fragment>
      {Object.keys(groupedData).map(broker => (
        <Col lg={6}>
          <Card key={broker}>
            <CardBody>
              <h4 className="card-title mb-3">{broker}</h4>

              <div>
                <BrokerBarChart data={groupedData[broker]} />
              </div>
            </CardBody>
          </Card>

        </Col>
      ))}
    </React.Fragment >
  );
};

function BrokerBarChart({ data }) {
  const chartData = {
    series: [{
      name: 'Lead Sayısı',
      data: data.map(item => item.lead_count)
    }],
    options: {
      chart: {
        type: 'bar',
      },
      xaxis: {
        categories: data.map(item => item.refer),
      },
      plotOptions: {
        bar: {
          horizontal: true, // Yatay bar grafikler için bu ayarı true yapın
        }
      },
      dataLabels: {
        enabled: false
      },
    }
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={350}
    />
  );
}

export default SourcesbyBroker;
