import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Container, Alert, CardHeader, Button, Label, Input, Table } from "reactstrap";

import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { get, post } from '../../helpers/api_helper';

const BrokerRejectedRefers = ({ data }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);
    const [brokerData, setBrokerData] = useState();

    const fetchData = async () => {
        try {
            const response = await get('lead/list-refers');
            const rejectedRefers = brokerData?.data?.data?.rejected_refers?.split(",");
            // API'den gelen verileri seçeneklere dönüştürme
            const newOptions = response.data
                .filter(item => !rejectedRefers?.includes(item.refer)) // Reddedilen referansları filtrele
                .map(item => ({ label: item.refer, value: item.refer }));
            setOptions(newOptions);
        } catch (error) {
            console.error('Veri çekme hatası', error);
        }
    };

    const getBrokerDetail = async () => {
        try {
            const response = await get(`broker/detail/${data.id}`);
            console.log(response, " get broker detail")
            setBrokerData(response);
        } catch (error) {
            console.error('Veri çekme hatası', error);
        }
    };
    useEffect(() => {
        getBrokerDetail();
        fetchData();
    }, []);


    const handleSave = async (inputValue) => {
        // Burada yeni oluşturulan öğeyi backend'e gönderme işlemini yapabilirsiniz
        try {
            await post('broker/refer/add', { refer: inputValue, broker: data.id })
            getBrokerDetail();
        } catch (error) {
            console.error('Öğe oluşturma hatası', error);
        }
    };

    const handleChange = async (option) => {
        await handleSave(option.value);
    };

    const handleDelete = async (refer) => {
        try {
            await post('broker/refer/remove', { refer: refer, broker: data.id })
            getBrokerDetail();
        } catch (error) {
            console.error('Öğe oluşturma hatası', error);
        }
    };

    return (

        <Card>
            {console.log(brokerData)}
            <CardHeader>
                İstenmeyen Kampanyalar
            </CardHeader>
            <CardBody>
                <div className="table-responsive">
                    {brokerData?.data?.rejected_refers ? (
                        <Table className="m-0" hover>
                            <tbody>
                                {brokerData.data.rejected_refers.split(",").map((data, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span style={{ marginRight: 15 }}>
                                                {data}
                                            </span>
                                            <span style={{ padding: "3px 10px", backgroundColor: 'red', color: 'white', fontSize: 12, cursor: 'pointer' }} onClick={() => handleDelete(data)}>Kaldır</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Alert>Reddedilen kampanya bulunamadı</Alert>
                    )}
                </div>
                <div className="input-area mt-3">
                    <Row className="mt-3 mb-0 d-flex ">
                        <Col md={12} xs="12" className="">
                            <Label>Refer seçiniz veya oluşturun</Label>
                            <CreatableSelect
                                isClearable
                                value={selectedOption}
                                onChange={handleChange}
                                onCreateOption={handleSave}
                                options={options}
                                placeholder="Seçim yapınız"
                            />
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    )
}
export default BrokerRejectedRefers;

