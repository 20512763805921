import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import tr from 'date-fns/locale/tr'; // Türkçe yerelleştirme için

function MyDateRangePicker() {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection',
        },
    ]);

    const [isOpen, setIsOpen] = useState(false); // Picker'ın açık olup olmadığını kontrol etmek için state

    return (
        <div>
            <button onClick={() => setIsOpen(!isOpen)}>Tarih Seç</button> {/* Butona tıklandığında picker'ı aç/kapat */}
            {isOpen && ( // Eğer isOpen true ise DateRangePicker'ı göster
                <div style={{ position: 'absolute', zIndex: 1000 }}> {/* Stillemeleri burada ayarlayabilirsiniz */}
                    <DateRangePicker
                        ranges={state}
                        onChange={(item) => setState([item.selection])}
                        locale={tr}
                    />
                </div>
            )}
        </div>
    );
}

export default MyDateRangePicker;