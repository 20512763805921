

import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import { useNavigate } from "react-router-dom";



const LeadTable = ({ data, size, email = true, id = true }) => {
    const navigate = useNavigate();

    function formatISODateToReadable(isoDateString) {
        const date = new Date(isoDateString);

        return date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // 24 saat formatı kullan
        });
    }


    function maskPhone(phone) {
        if (!phone) return "";

        const length = phone.length;

        if (length <= 2) {
            return phone;
        }

        return "*".repeat(length - 2) + phone.substring(length - 2);
    }

    function maskEmail(email) {
        if (!email) return "";

        const [username, domain] = email.split("@");
        if (!domain) return email;

        const visibleUsernameChars = 4;
        const maskedUsername = username.length > visibleUsernameChars
            ? "*".repeat(username.length - visibleUsernameChars) + username.substring(username.length - visibleUsernameChars)
            : username;

        return `${maskedUsername}@${domain}`;
    }
    return (
        <React.Fragment>
            <div className="table-rep-plugin">
                <div className="table-responsive mb-0" data-pattern="priority-columns">
                    <Table id="tech-companies-1" bordered responsive hover size={size}>
                        <thead>
                            <tr>
                                {id && (<th>#</th>)}
                                <th data-priority="1">Müşteri Adı - Soyadı</th>
                                {email && (<th data-priority="3">E-Posta</th>)}
                                <th data-priority="1">Telefon</th>
                                <th data-priority="3">UTM Kaynak</th>
                                <th data-priority="3">Durum</th>
                                <th data-priority="6">Şirket</th>
                                <th data-priority="6">Tarih </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.records?.map((data, index) => (
                                <tr
                                    style={{
                                        verticalAlign: "middle",
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => window.location.href = `/lead-detail/${data.id}`}
                                >

                                    {id && (<th>{data.id}</th>)}

                                    <td>{data.name}</td>
                                    {email && (<td>{maskEmail(data.email)}</td>)}
                                    <td>(+{data.code})-{maskPhone(data.phone)}</td>
                                    <td>{data.refer}</td>
                                    <td className='text-center'>

                                        {data.transfered ? (
                                            <div className="badge bg-success-subtle text-dark font-size-12">Transfer Edildi ✅</div>
                                        ) : (
                                            <div className="badge bg-danger-subtle text-dark font-size-12">Beklemede ⚠️</div>
                                        )}
                                    </td>
                                    <td>{data.broker ? data.broker_detail?.name : "-"}</td>
                                    <td>{formatISODateToReadable(data.createdAt)}</td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>
            </div>
        </React.Fragment>
    )
};
export default LeadTable;

