/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Container, Label } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PaginationNumbers from "../../components/Common/PaginationNumbers";
import api_helper, { get } from '../../helpers/api_helper'
import LeadsFilter from "../../components/Common/LeadsFilter";
import LeadTable from "../../components/Common/LeadTable";
import PageSizeSelector from "../../components/Common/PageSizeSelector";



const LeadPage = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Anasayfa", link: "/" },
    { title: "Müşteriler", link: "#" },
  ]);

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(15);

  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getData();
  }, [currentPage, dataLimit, filterData])

  const handlePageSizeChange = (newSize) => {
    setDataLimit(newSize);
  };

  const handleFilterChange = (filters) => {
    setFilterData(filters);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setFilterData({});
    setCurrentPage(1);
  }
  const getData = async () => {
    try {
      setIsLoading(true);
      const queryParameters = `page=${currentPage}&limit=${dataLimit}`;
      const filterQuery = Object.keys(filterData).map(key => `${key}=${filterData[key]}`).join('&');
      const response = await get(`lead/search?${queryParameters}&${filterQuery}`);
      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.data;
      setData(data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Lead Kayıtları" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <LeadsFilter onFilterSubmit={handleFilterChange} onClearFilters={handleClearFilters}></LeadsFilter>
            </Col>

            <Col xs={12}>
              <Card>
                {isLoading && (
                  <div>Yükleniyor.</div>
                )}
                {error && (
                  <div>{error.message}.</div>
                )}
                {data && (
                  <CardBody>
                    <div className="d-flex justify-content-end">
                      Toplam {data.total} kayıt bulundu.
                    </div>
                    <div className=" d-flex justify-content-between align-items-center">
                      <div className="mb-3">
                        <PageSizeSelector pageSize={dataLimit} onPageSizeChange={handlePageSizeChange}></PageSizeSelector>

                      </div>
                      <div>
                        <PaginationNumbers setCurrentPage={(e) => setCurrentPage(e)} currentPage={data.currentPage} totalPages={data.totalPages} />
                      </div>

                    </div>

                    <LeadTable data={data}></LeadTable>
                    <PaginationNumbers setCurrentPage={(e) => setCurrentPage(e)} currentPage={data.currentPage} totalPages={data.totalPages} />
                  </CardBody>
                )}

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

}


export default LeadPage;
