/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Container, Alert, CardHeader, Button, Label, Input, Table } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import api_helper, { get, post } from '../../helpers/api_helper'
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import Select from "react-select";
import Creatable, { useCreatable } from 'react-select/creatable';
import BrokerRejectedRefers from "../../components/Common/BrokerRejectedRefers";
import LeadTable from "../../components/Common/LeadTable";
import PaginationNumbers from "../../components/Common/PaginationNumbers";
import LeadsFilter from "../../components/Common/LeadsFilter";





const BrokerDetail = () => {
  const { id } = useParams();
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Anasayfa", link: "/" },
    { title: "Firmalar", link: "/brokers" },
    { title: `Firma Detayları`, link: "#" },
  ]);
  const [data, setData] = useState();
  const [brokerCustomers, setBrokerCustomers] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [visibleDeveloperPanel, setVisibleDeveloperPanel] = useState(false);
  const [transferLogPanel, setTransferLogPanel] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(15);
  const [filterData, setFilterData] = useState({});

  const [brokerSettings, setBrokerSettings] = useState({
    name: '',
    percent: '',
  });
  useEffect(() => {
    console.log(id);
    getData();
  }, []);

  useEffect(() => {
    getBrokerLeads();
  }, [currentPage, dataLimit, filterData])



  const handleChange = (e) => {
    setBrokerSettings({ ...filterData, [e.target.name]: e.target.value });
  };
  const updateBroker = async () => {
    try {
      setIsLoading(true);

      const response = await post(`broker/update/${id}`, {
        name: brokerSettings.name,
        percent: brokerSettings.percent
      });

      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }

      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }


  const handleFilterChange = (filters) => {
    setFilterData(filters);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setFilterData({});
    setCurrentPage(1);
  }

  const getData = async () => {
    try {
      setIsLoading(true);

      const response = await get(`broker/detail/${id}`);
      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.data;
      setData(data);
      setBrokerSettings({
        name: data.name,
        percent: data.percent
      })
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.data.message) {
        setError(error.response.data);
        setIsLoading(false);

      } else {
        setError(error);
        setIsLoading(false);
      }
    }
  }

  const getBrokerLeads = async () => {
    try {
      setIsLoading(true);
      const queryParameters = `page=${currentPage}&limit=${dataLimit}`;
      const filterQuery = Object.keys(filterData).map(key => `${key}=${filterData[key]}`).join('&');
      const response = await get(`lead/search?${queryParameters}&${filterQuery}&broker=${id}`);
      if (!response.status === 200) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.data;
      setBrokerCustomers(data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Firma Detayı" breadcrumbItems={breadcrumbItems} />
          <Row>
            {isLoading && (
              <div>
                <Alert color="light">Yükleniyor...</Alert>
              </div>
            )}
            {error && (
              <div>
                <Col xs={12} md={12} >
                  <Alert color="danger">{error.message}</Alert>
                </Col>
              </div>
            )}


            {data && (
              <Row>
                <Col xs={12} md={12} >
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h2>
                        <div>
                          # <strong>{data.name}</strong>
                        </div>
                      </h2>
                    </div>

                    <Row className="mb-3">
                      <Col xs="12" md="8">
                        <Card>
                          <CardHeader>
                            Firma Ayarları
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3">
                              <Col md={8} xs="12" className="mb-3">
                                <Label className="">Firma Adı</Label>
                                <Input type="text" value={brokerSettings.name} name="name" onChange={handleChange} placeholder="Firma Adı" id="example-text-input" />
                              </Col>
                              <Col md={4} xs="12" className="mb-3">
                                <Label className="">Yüzdelik</Label>
                                <Input type="number" value={brokerSettings.percent} name="percent" onChange={handleChange} placeholder="" id="example-text-input" />
                              </Col>
                              <Col md={12} xs="12" className="d-flex justify-content-end">
                                <Button color="success" onClick={updateBroker}>Güncelle</Button>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs="12" md="4">
                        <BrokerRejectedRefers data={data}></BrokerRejectedRefers>
                      </Col>
                    </Row>
                  </div>


                </Col>
                <Col xs={12}>
                  <LeadsFilter onFilterSubmit={handleFilterChange} onClearFilters={handleClearFilters} prefferedBroker={id}></LeadsFilter>
                </Col>
                <Col xs={12}>
                  <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center"><div>Firma Müşterileri</div>

                      <PaginationNumbers setCurrentPage={(e) => setCurrentPage(e)} currentPage={brokerCustomers?.currentPage} totalPages={brokerCustomers?.totalPages} />
                    </CardHeader>
                    <CardBody>
                      <LeadTable data={brokerCustomers} size={"sm"}></LeadTable>
                    </CardBody>

                  </Card>
                </Col>
                <Col xs={12}>
                  <div className="mb-3">
                    <h3>Buradaki veriler developer için</h3>
                  </div>
                  <Card>
                    <CardHeader >
                      <strong>JSON Response</strong>
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        size="sm"
                        onClick={() => setVisibleDeveloperPanel(!visibleDeveloperPanel)}>
                        {visibleDeveloperPanel ? "Gizle" : "Göster"}
                      </Button>
                    </CardHeader>
                    <CardBody style={{
                      display: visibleDeveloperPanel ? "block" : "none",
                    }}>
                      <code>{JSON.stringify(data)}</code>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader >
                      <strong>Transfer Logs</strong>
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        size="sm"
                        onClick={() => setTransferLogPanel(!transferLogPanel)}>
                        {transferLogPanel ? "Gizle" : "Göster"}
                      </Button>
                    </CardHeader>
                    <CardBody style={{
                      display: transferLogPanel ? "block" : "none",
                    }}>
                      <code>{JSON.stringify(data)}</code>
                    </CardBody>
                  </Card>

                </Col>

              </Row>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

}


export default BrokerDetail;
