

import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Label, Row, Col, Input, Button, FormGroup, InputGroup } from 'reactstrap';
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr"
import { get } from '../../helpers/api_helper';

const LeadsFilter = ({ onFilterSubmit, onClearFilters, prefferedBroker }) => {

    const [filterData, setFilterData] = useState({
        name: '',
        email: '',
        phone: '',
        refer: '',
        broker: prefferedBroker || '',
        transfered: '',
        startDate: '',
        endDate: ''
    });

    const [brokers, setBrokers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value });
    };

    const onFilterSubmitHandle = () => {
        onFilterSubmit(filterData);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onFilterSubmitHandle(filterData);
    };

    const handleClear = () => {
        setFilterData({
            name: '',
            email: '',
            phone: '',
            refer: '',
            broker: '',
            transfered: '',
            startDate: '',
            endDate: ''
        });

        onClearFilters();
    };

    const onDatesChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setFilterData({
                ...filterData,
                startDate: selectedDates[0].toISOString(),
                endDate: selectedDates[1].toISOString()
            });
        }
    };



    const getBrokers = async () => {
        try {
            setIsLoading(true);
            const response = await get("broker/list");
            if (response.status === 200) {
                setBrokers(response.data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        getBrokers();
    }, [])


    return (
        <React.Fragment>
            <Card>
                <CardHeader>Lead Filtreleme</CardHeader>
                <form onSubmit={handleSubmit}>
                    <CardBody>
                        <Row className="mb-0">
                            <Col xs={12} md={2}>
                                <Label>Müşteri Adı</Label>
                                <Input className='form-control-md' value={filterData.name}
                                    type="text" defaultValue="" placeholder='Müşteri Adı' name="name" onChange={handleChange} />
                            </Col>
                            <Col xs={12} md={2}>
                                <Label>E-Posta</Label>
                                <Input className='form-control-md' value={filterData.email} type="text" defaultValue="" placeholder='Müşteri E-Posta' name="email" onChange={handleChange} />
                            </Col>
                            <Col xs={12} md={2}>
                                <Label>Telefon</Label>
                                <Input className='form-control-md' value={filterData.phone} type="text" defaultValue="" placeholder='5XX XXX XXXX' name="phone" onChange={handleChange} />
                            </Col>
                            <Col xs={12} md={2}>
                                <Label>Refer</Label>
                                <Input className='form-control-md' value={filterData.refer} type="text" defaultValue="" placeholder='UTM Source & Refer' name="refer" onChange={handleChange} />
                            </Col>
                            {!prefferedBroker && (
                                <Col xs={12} md={2}>
                                    <Label>Şirket</Label>
                                    <select className="form-control " name="broker" onChange={handleChange} value={filterData.broker}>
                                        <option value="">Seçilmedi</option>
                                        {brokers.map((broker) => (
                                            <option key={broker.id} value={broker.id}>{broker.name}</option>
                                        ))}
                                    </select>
                                </Col>
                            )}

                            <Col xs={12} md={2}>
                                <Label>Durum</Label>
                                <select className="form-control " name="transfered" onChange={handleChange} value={filterData.transfered}>
                                    <option value="">Seçilmedi</option>
                                    <option value="1">Transfer Edildi</option>
                                    <option value="0">Beklemede</option>
                                </select>
                            </Col>
                            <Col xs={4}>
                                <FormGroup className="mt-3">
                                    <Label>Tarih Aralığı</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            value={[filterData.startDate, filterData.endDate]}
                                            className="form-control d-block"
                                            placeholder="Başlangıç ve bitiş tarihi seçiniz"
                                            options={{
                                                mode: "range",
                                                enableTime: true,
                                                dateFormat: "Y-m-d H:i:S",
                                                locale: Turkish,
                                                onChange: onDatesChange
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-end mt-3">
                                    <Button color='secondary' style={{ marginRight: 5 }} size='md' onClick={handleClear}>Temizle</Button>
                                    {' '}
                                    <Button color='success' size='md' type="submit">Filtrele</Button>
                                </div>
                            </Col>



                        </Row>
                    </CardBody>
                </form>
            </Card>
        </React.Fragment>
    )
};
export default LeadsFilter;

