import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

const RecentlyActivity = ({ data }) => {

  function formatISODateToReadable(isoDateString) {
    const date = new Date(isoDateString);

    return date.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // 24 saat formatı kullan
    });
  }
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">En Son Hareketler</h4>

            <SimpleBar style={{ maxHeight: "330px" }}>
              <ul className="list-unstyled activity-wid">

                {data?.map((item) => (
                  <>
                    {item.transfered && (
                      <li className="activity-list">
                        <div className="activity-icon avatar-xs">
                          <span className="avatar-title bg-primary-subtle text-primary rounded-circle">
                            <i className="ri-user-shared-line"></i>
                          </span>
                        </div>
                        <div>
                          <div>
                            <h5 className="font-size-13">
                              {formatISODateToReadable(item?.updatedAt)}
                            </h5>
                          </div>

                          <div>
                            <Link to={`/lead-detail/${item.id}`} className="text-muted mb-0">(<strong>#{item.id}</strong>) {item.name}, <strong>{item.broker_detail?.name}</strong> şirketine transfer edildi.</Link>

                          </div>
                        </div>
                      </li>
                    )}
                    <li className="activity-list">
                      <div className="activity-icon avatar-xs">
                        <span className="avatar-title bg-primary-subtle text-primary rounded-circle">
                          <i className="ri-loader-4-fill"></i>
                        </span>
                      </div>
                      <div>
                        <div>
                          <h5 className="font-size-13">
                            {formatISODateToReadable(item?.createdAt)}
                          </h5>
                        </div>

                        <div>
                          <Link to={`/lead-detail/${item.id}`} className="text-muted mb-0">(<strong>#{item.id}</strong>) {item.name}, <span className="text-info">{item.refer}</span> kampanyasına başvurdu.</Link>
                        </div>
                      </div>
                    </li>
                  </>
                ))}

              </ul>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RecentlyActivity;
